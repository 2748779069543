
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { ValidationError } from 'class-validator';
import moment from 'moment';

import UserService, { UserServiceS } from '@/modules/user/user.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';

import Dialog from '@/modules/common/components/ui-kit/dialog.vue';
import ValidationErrorComponent from '@/modules/common/components/ui-kit/validation-error.vue';
import EventGroup from '@/modules/events/interfaces/event-group.enum';

import ViewEventTitle from './view-event-title.vue';

@Component({
    components: {
        ValidationErrorComponent,
        Dialog,
        ViewEventTitle,
    },
    filters: {
        NumberOfGuestsFilter: (value: number[]) => {
            if (!Array.isArray(value)) return value;

            if (!value) return '-';

            if (value[0] === value[1]) {
                return value[0];
            }

            if (value[0] === 0 && value[1] > 0) {
                return `up to ${value[1]}`;
            }

            if (value[1] === undefined && value[0] > 0) {
                return `${value[0]}+`;
            }

            if (value[0] > 0 && value[1] > 0) {
                return value.join('-');
            }

            return value[0];
        },
    },
})
export default class ViewEventForm extends Vue {
    @Inject(EventsManagerServiceS)
    private eventsManagerService!: EventsManagerService;

    @Inject(UserServiceS)
    private userService!: UserService;

    @Prop({ required: false })
    private eventId?: string;

    isDeleting: boolean = false;
    isIgnoring: boolean = false;
    isPending: boolean = false;

    validationErrors: ValidationError[] | null = null;

    get currentHotelId() {
        return +this.$route.params.hotelId || this.userService.currentHotelId!;
    }

    get canBeIgnored() {
        if (this.isMarket) return true;

        if (this.event?.group === EventGroup.HOLIDAY) {
            return false;
        }

        // NOTE: or cars
        // FIXME events should be ignoreable for cluster and chain as well
        //       BE doesn't support this feature yet
        const isHotel = !['cluster', 'chain'].includes(this.userService.viewAs!);

        return isHotel && !this.isMyEvent;
    }

    get isOwnerCurrentHotel() {
        if (this.event) {
            return this.event.entityId === this.currentHotelId;
        }
        return false;
    }

    get isApproved() {
        if (!this.event) return false;
        return this.event.group === EventGroup.MAIN;
    }

    get isHoliday() {
        if (!this.event) return false;
        return this.event.group === EventGroup.HOLIDAY;
    }

    get isMarket() {
        if (!this.event) return false;
        return this.event.group === EventGroup.MARKET;
    }

    get isMyEvent(): boolean {
        const { event } = this;

        if (!event || !this.userService.user) {
            return false;
        }

        const isCreator = event.creatorId === this.userService.user.id;

        if (isCreator) {
            return true;
        }

        const isChainUser = ['cluster', 'chain'].includes(this.userService.userLevel);
        const isTypeHotel = event.entityType === 'hotel';

        if (isChainUser && isTypeHotel) {
            return this.userService.user.hotelIds.includes(Number(event.entityId));
        }

        return false;
    }

    get isIgnored() {
        return this.eventsManagerService.isEventIgnored(this.eventId!);
    }

    get event() {
        if (this.eventId) {
            return this.eventsManagerService.getEventById(this.eventId);
        }

        return null;
    }

    formatDate(date: string) {
        const correctedDate = new Date(date);
        correctedDate.setMinutes(correctedDate.getTimezoneOffset());

        return moment(correctedDate).format('ddd DD/MM/YYYY');
    }

    editEvent() {
        this.$router.push({
            name: this.$route.name!.replace('view', 'edit'),
            params: {
                ...this.$route.params,
                eventId: String(this.eventId),
            },
        });
    }

    toggleDeleteEventPopup(): void {
        this.isDeleting = !this.isDeleting;
    }

    toggleIgnoreEventPopup(): void {
        this.isIgnoring = !this.isIgnoring;
    }

    ignoreEvent() {
        this.isPending = true;
        const method = this.isIgnored
            ? 'restoreIgnoredEvent'
            : 'ignoreEvent';
        this.eventsManagerService[method](this.eventId!)
            .finally(() => {
                this.isPending = false;
                this.isIgnoring = false;
                this.close();
            });
    }

    deleteEvent() {
        this.isPending = true;

        this.eventsManagerService
            .removeEvent(this.eventId!)
            .finally(() => {
                this.isPending = false;
                this.isDeleting = false;
                this.close();
            });
    }

    close() {
        this.$emit('close');
    }
}
